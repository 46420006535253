.folder-sidebar {
    border: solid 1px black;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.add-folder {
    border: solid 1px black;
    margin: 10px;
    padding: 5px;
    display: flex;
    justify-content: center;
    width: 150px;
}

.active {
    background: lightblue;
}