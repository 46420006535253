.folder-sidebar {
    border: solid 1px black;
    flex: 1;
}

.back-button {
    border: solid 1px black;
    margin: 10px;
    padding: 5px;
    display: flex;
    justify-content: center;
    width: 150px;
}